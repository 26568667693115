(function () {
    'use strict';

    // via http://stackoverflow.com/a/10456644
    window.array_chunk = function(array, chunkSize) {
        return [].concat.apply([],
            array.map(function(elem,i) {
                return i%chunkSize ? [] : [array.slice(i,i+chunkSize)];
            })
        );
    };

    $('[data-hook="close-overlay"]').on('click', function () {
        $('.video-container iframe').each(function(){
            this.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        });
        
        $(this).parents('.overlay-wrap').removeClass('active');
        $('body').removeClass('has-overlay');
    });


})();