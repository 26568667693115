$( document ).ready(function() {
    $('.expand').click(function(e) {
        expandChilds(this);

        e.preventDefault();
    });

    /*$('.cell-container').click(function(e) {
        expandChilds(this);

        e.preventDefault();
    });*/

    if (!window.AppConf.postCon) {
        setInterval(updateRundown, 60000);
    }

    $(".table-block").swipe({
        allowPageScroll: "vertical",
        swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
            if (distance > 0) {
                var docWidth = $(document).width();

                var marginLeft = parseInt($(this).find('.cell-container').first().css('margin-left').replace("px", ""));

                if (docWidth < 692) {
                    if (direction == 'left') {
                        if (Math.abs(marginLeft) + docWidth < 692) {
                            marginLeft = marginLeft - distance;

                            if (Math.abs(marginLeft) + docWidth > 692) {
                                marginLeft = docWidth - 692;
                            }
                        }
                    } else if (direction == 'right') {
                        if (marginLeft < 0) {
                            marginLeft = marginLeft + distance;

                            if (marginLeft >= 0) {
                                marginLeft = 0;
                            }
                        }
                    }
                    //$(this).find('.cell-container').css('margin-left', marginLeft+'px');
                    $(this).find('.cell-container').animate({
                        marginLeft: marginLeft
                    }, 500, function() {
                    });
                    $(this).find('.image').animate({
                        marginLeft: marginLeft
                    }, 500, function() {
                    });
                }
            } else {
                if (!window.AppConf.postCon) {
                    expandChilds(event.target);
                } else {
                    var angleid = $(event.target).attr('data-hook-anglegrid');
                    if (typeof angleid === typeof undefined || angleid === false) {
                       angleid = $(event.target).parent().attr('data-hook-anglegrid'); 
                    }
                    
                    if (typeof angleid !== typeof undefined && angleid !== false) {
                        var $overlay = $('#anglegrid-' + angleid);
    
                        if($overlay.length > 0) {
                            $('body').addClass('has-overlay');
                            $overlay.addClass('active');
                        }
                    }
                }
            }
        },
        threshold:0
    });
});

function expandChilds(sender) {
    var tableId = $(sender).closest('.row').attr('data-table-id');
    var parendId = $(sender).closest('.row').attr('data-row-id');

    if ($(sender).hasClass('active')) {
        $(sender).removeClass('active');

        $('.tracks[data-parent-id="'+parendId+'"][data-table-id="'+tableId+'"]').slideUp();
    } else {
        $(sender).addClass('active');

        $('.tracks[data-parent-id="'+parendId+'"][data-table-id="'+tableId+'"]').slideDown();
    }
}

function updateRundown() {
    $.getJSON(AppConf.baseUrl + 'rundown', {})
        .done(function (rundown) {
            $('.row').removeClass('played');
            $('.row').removeClass('onair');

            $.each(rundown, function( tableId, rows ) {
                $.each(rows, function( rowId, state ) {
                    if (state.length > 0) {
                        $('.row[data-table-id="'+tableId+'"][data-row-id="'+rowId+'"]').addClass(state);
                    }
                });
            });
        });
}