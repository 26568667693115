(function () {
    'use strict';

    if($('#media-gallery').length === 0) {
        return;
    }

    var returnToOverlay = null; //reference to overlay to be opened after another overlay closes

    var gridTpl = _.template($('#tpl-gallerygrid').html());

    //breakpoints
    var breakpoints = {
        'b5': matchMedia('(min-width: 1031px)'),
        'b4': matchMedia('(max-width: 1029px)'),
        'b3': matchMedia('(max-width: 849px)'),
        'b2': matchMedia('(max-width: 679px)')
    };
    var currentBreakpoint = '';
    $(window).on('resize', function () {
        var matchingBp = '';
        _.each(breakpoints, function (bp, name) {
            if(bp.matches) {
                matchingBp = name;
            }
        });

        if(matchingBp !== currentBreakpoint) {
            PubSub.publish('mediaGalleryBreakpoint', matchingBp);
            currentBreakpoint = matchingBp;
        }
    }).trigger('resize');

    var Gallery = function ($container, media, angleId) {
        angleId = angleId || 'global';

        var $wrap = $container.find('.gallery-wrap');
        var pageSize = {
            b5: 15,
            b4: 12,
            b3: 9,
            b2: 2,
        }[currentBreakpoint];

        var mediaPages = {};
        _.each(media, function (items, type) {
            mediaPages[type] = array_chunk(_.values(items), pageSize);
        });

        mediaPages.all = [];
        ['images', 'videos', 'slides'].forEach(function (type) {
            _.each(media[type], function (item) {
                item.type = type;
                mediaPages.all.push(item);
            });
        });
        mediaPages.all = array_chunk(_.values(mediaPages.all), pageSize);

        var html = gridTpl({media: mediaPages, angleId: angleId});
        $wrap.html(html);

        var gridWidth = $('#media-gallery').find('.grid-page').first().width();

        //navigate between types
        $container.find('[data-hook-filter]').on('click', function (evt) {
           evt.preventDefault();
            $(this).parent()
                .addClass('active')
                .siblings('.active')
                .removeClass('active');

            $container.find('.gallery[data-type="' + $(this).data('hook-filter') + '"]')
                .addClass('active')
                .siblings('.active')
                .removeClass('active');
        });

        //pagination
        $wrap.children().each(function () {
            var $gallery = $(this);
            var currentPage = 1;
            var pageCount = $gallery.find('.grid-page').length;
            $gallery.find('.gallery-grid').scrollLeft(0); //reset

            $gallery.find('[data-hook-paginate]').on('click', function () {
                var mod = parseInt($(this).data('hook-paginate'), 10);
                if(currentPage + mod > 0 && currentPage + mod <= pageCount) {
                    currentPage += mod;
                }

                $gallery.find('.gallery-grid').stop().animate({scrollLeft: (currentPage - 1) * gridWidth});
                $gallery.find('[data-hook="current-page"]').text(currentPage);
            });
        });
    };

    var initGalleries = function () {
        new Gallery($('#media-gallery'), mediaGalleryData.byType);

        $('.media-gallery-container-angle').each(function () {
            new Gallery($(this), mediaGalleryData.byAngle[$(this).data('angleid')], $(this).data('angleid'));
        });
    };

    initGalleries();
    PubSub.subscribe('mediaGalleryBreakpoint', function (bp,v) {
        initGalleries();
    });

    //details overlays:
    $('.media-details-overlay').each(function () {
        var $detailsOverlay = $(this);

        $detailsOverlay.find('[data-hook-filter]').on('click', function (evt) {
            evt.preventDefault();
            $(this).parent()
                .addClass('active')
                .siblings('.active')
                .removeClass('active');

            var $typeContainer = $detailsOverlay.find('.details-slider[data-type="' + $(this).data('hook-filter') + '"]');
            $typeContainer.addClass('active')
                          .siblings('.active').removeClass('active');

          $typeContainer.data('setWidth')();

            var $iframePlaceholder = $typeContainer.find('.iframe-placeholder');
            if($iframePlaceholder.length > 0) {
                var src = $iframePlaceholder.data('src');
                $iframePlaceholder.replaceWith('<iframe src="' + src + '" frameborder="0" scrolling="no" allowfullscreen></iframe>');
            }
        });

        //individual types
        $detailsOverlay.find('.details-slider').each(function () {
            var $slider = $(this);
            var currentPage = 1;
            var pageCount = $slider.find('.slides li').length;
            var slideWidth = 0;

            var slide = function () {
                var $iframePlaceholder = $slider.find('.slide').eq(currentPage - 1).find('.iframe-placeholder');
                if($iframePlaceholder.length > 0) {
                    var src = $iframePlaceholder.data('src');
                    $iframePlaceholder.replaceWith('<iframe src="' + src + '" frameborder="0" scrolling="no" allowfullscreen></iframe>');
                }

                $slider.find('.slides').stop().animate({scrollLeft: (currentPage - 1) * slideWidth});
            };

            $slider.find('[data-hook-paginate]').on('click', function () {
                var mod = parseInt($(this).data('hook-paginate'), 10);
                if(currentPage + mod > 0 && currentPage + mod <= pageCount) {
                    currentPage += mod;
                    slide();
                }
            });

            $slider.data('goToIndex', function (index) {
                currentPage = index + 1;
                slide();
            });

            $slider.data('setWidth', function () {
                slideWidth = $detailsOverlay.find('.slides:first').width();
            });
        });

    });


    // open details overlay
    $('[data-hook-mediadetails]').on('click', function () {
        var target = $(this).data('hook-mediadetails').split(':');
        var overlayId = target[0];
        var mediaId = target[1];
        var type = $(this).data('mediatype');
        var $overlay = $('#mediadetails-' + overlayId);
        var $slide = $overlay.find('[data-mediaid="' + mediaId + '"]');
        var $slider = $slide.parents('.details-slider');

        //store and close current overlay:
        if($('.overlay-wrap.active').length > 0) {
            returnToOverlay = $('.overlay-wrap.active');
            $('.overlay-wrap.active').removeClass('active');
        }

        $('body').addClass('has-overlay');
        $overlay.addClass('active');
        $overlay.find('.gallery-filter .filter-' + type).addClass('active').siblings().removeClass('active');
        $overlay.find('.details-slider[data-type="' + type + '"]').addClass('active').siblings('.details-slider').removeClass('active');

        $slider.data('setWidth')();
        $slider.data('goToIndex')($slide.index());
    });

    //open anglegrid overlay
    $('[data-hook-anglegrid]').on('touchstart click', function () {
        //var $overlay = $('#anglegrid-' + $(this).data('hook-anglegrid'));

        //if($overlay.length > 0) {
            //$('body').addClass('has-overlay');
            //$overlay.addClass('active');
        //}
    });

    $('.go-back').on('click', function () {
        $(this).parents('.overlay-wrap').removeClass('active');
        
        $('.video-container iframe').each(function(){
            this.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        });

        if(returnToOverlay) {
            returnToOverlay.addClass('active');
            returnToOverlay = null;
        }
        else {
            $('body').removeClass('has-overlay');
        }
    });

    //voting
    $('.stars:not(.stars-disabled) [data-hook-rate]')
        .on('mouseover', function () {
            $(this).prevAll().andSelf().addClass('star-hover');
        })
        .on('mouseleave', function () {
            $(this).prevAll().andSelf().removeClass('star-hover');
        })
        .on('click', function () {
            var $this = $('[data-hook-rate="' + $(this).attr('data-hook-rate') + '"]'); //find all associated rating-widgets
            var data = $(this).data('hook-rate').split(':');
            var angleId = data[0];
            var points = data[1];

            $.post(AppConf.baseUrl = 'vote/' + angleId + '/' + points, {}, 'json')
                .done(function (data) {
                    $this.prevAll().andSelf().addClass('star-selected');
                    $this.siblings().andSelf().off()
                         .parent().addClass('stars-disabled');

                    var votesStr = String(Math.round(parseFloat(data.votes, 10) * 100) / 100).replace('.', ',');
                    $('[data-hook-ratingresult="' + angleId + '"]').text(votesStr);
                });
        });
})();